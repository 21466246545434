import { Component } from "react";
import ReactLoading from 'react-loading';
import i18n from 'i18next';

class Home extends Component {
  constructor(props) {
    super(props);
  
    var usePassword = false;
    if(props.info.use_password == '1'){
      usePassword = true;
    }
    this.state = {
      isLoading: false,
      name: props.info.name,
      pdf: props.info,
      selectedFile: null,
      showError: '',
      usePassword: usePassword,
      accessPassword: props.info.password
    }

  }

  componentDidMount() {
   }
 
 


  createPDF = () => {
    if((this.state.name == '' || this.state.name==this.state.pdf.name) && this.state.selectedFile == null && (this.state.usePassword && this.state.accessPassword == '')){
        this.setState({ showError: i18n.t("edit_link_error") });
    }else{
      //upload
      if(this.state.usePassword && this.state.accessPassword == ''){
        this.setState({ showError: i18n.t("add_link_error_password") });
      }else{
      this.uploadPDF();
      }
  }
  }

  uploadPDF = () => {
    if(this.state.isLoading){
        return;
    }
    this.setState({ isLoading: true });
    var sessiontoken = localStorage.getItem('client_token');
    
    if(sessiontoken === null){
       window.localStorage.clear(); 
       window.location.reload();
       return;
    }

    const data = new FormData();
    
    data.append('token', sessiontoken);
    data.append('name', this.state.name);
    data.append('id', this.state.pdf.id);
    if(this.state.selectedFile == null){
        data.append('file_info', 'no-file');
    }else{
        data.append('file', this.state.selectedFile );
        data.append('file_info', 'ok-file');
    }

    if(this.state.usePassword){
      data.append('password', this.state.accessPassword);
    }

    var apiUrl = "https://p-d-f.link/api/board/v1/pdf/action/edit";

    fetch(apiUrl, {
         method: 'POST',
         body: data
    }).then((response) =>  {
       this.goHome();
    })
  }

  goHome = () => {
    this.props.dismiss();
  }

  insertName = (e) => {
    var insert = String(e.target.value);      
    this.setState({ name: insert, showError: ''});     
}

insertPassword = (e) => {
  var insert = String(e.target.value);      
  this.setState({ accessPassword: insert, showError: ''});     
}

changeHandler = (e) => {
    this.setState({ selectedFile: e.target.files[0], showError: '' });
}

checkboxAction = (e) => {

  if(!this.state.usePassword){
      this.setState({ usePassword: true, showError: ''}); 
  }else{
      this.setState({ usePassword: false, showError: ''});  
  }
}

  render() {
    return (
      <div>
        {this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    width: '100%',
    height: '100%'
      }}>
        <ReactLoading type="spin" color="red" height="30px" width="30px" />
        </div>
      }

                <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="text"  
                name="name"
                value={this.state.name}
                placeholder={i18n.t("link_name")}
                onChange={this.insertName}
                />
                </div>
                </div>
                </div>

                <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <input type="file" accept=".pdf" name="file" onChange={this.changeHandler} />
                </div>

                <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                <div style={{ marginTop: '25px', color: 'grey', fontWeight: 'normal', fontSize: '12px'}}>{i18n.t("protect_password")}
                <div><input style={{marginTop: '3px'}} checked={this.state.usePassword} onChange={this.checkboxAction} type="checkbox" /> </div>
                </div>
                </div>
                {this.state.usePassword &&
                <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="text"  
                name="password"
                autoComplete="off"
                value={this.state.accessPassword}
                placeholder={i18n.t("access_password")}
                onChange={this.insertPassword}
                />
                </div>
                </div>
                </div>}

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'red', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

            <div  style={{ marginTop: '100px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'red', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.createPDF}
            >{i18n.t("save")}</p>
            </div>
            <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'normal', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.goHome}
             >{i18n.t("cancel")}</p>
             </div>
           

        <br />


      </div>
    );
  }
}

export default Home;
