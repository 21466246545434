import { Component } from "react";
import i18n from 'i18next';

class Login extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      email: '',
      password: '',
      isLoading: false
    }

  }
  componentDidMount() {
   }
 
 
   insertEmail = (e) => {
    var insert = String(e.target.value);      
    this.setState({ email: insert, showError: ''});     
}

insertPassword = (e) => {
    var insert = String(e.target.value);      
    this.setState({ password: insert, showError: ''});     
}

goBack = () => {
    this.props.goBack(false);
}

confirm = () => {
    if(this.state.email.length < 6){
        this.setState({ showError: i18n.t("enter_email") });
    }else if(this.state.password.length < 6){
        this.setState({ showError: i18n.t("not_valid_password_2")});
    }else{
        this.loginAccount();
    }
}

loginAccount = () => {
    if(this.state.isLoading){
        return;
    }
    this.props.loading(true);
    this.setState({ isLoading: true });

    
    var sessiontoken = localStorage.getItem('client_token');
    
    if(sessiontoken === null){
       window.localStorage.clear(); 
       window.location.reload();
       return;
    }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          email: this.state.email,
          password: this.state.password
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/user/login";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
          if(r.status == 'ko'){
            this.setState({ showError: i18n.t("not_valid_data"), isLoading: false});
            this.props.loading(false);
          }else{
            this.props.goBack(true);
          }
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
   
}




  render() {
    return (
      <div>
    <br />
    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="text"  
                name="email"
                value={this.state.email}
                placeholder={i18n.t("email")}
                onChange={this.insertEmail}
                />
                </div>
                </div>
                </div>

                <div  style={{marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t("password")}
                onChange={this.insertPassword}
                />
                </div>
                </div>
                </div>

     

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'red', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

                <div  style={{ marginTop: '15px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '150px', borderRadius: '5px', backgroundColor: 'red', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirm}
            >{i18n.t("login")}</p>
            </div>
            <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'normal', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.goBack}
             >{i18n.t("cancel")}</p>
             </div>

      </div>
    );
  }
}

export default Login;
