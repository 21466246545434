import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      no_link: "No link created",
      create_new: "Create new",
      start_now: "Start now",
      enter_password: "Enter your password to confirm",
      enter_email: "Please insert your email address",
      not_valid_password: "Not valid password",
      not_valid_password_2: "Password must contains at least 6 characters",
      not_valid_password_3: "The current password is not valid",
      change_password: "Change password",
      delete_account: "Delete account",
      logout: "Logout",
      need_account: "You need an account to upload and archive PDF!",
      need_account_2: "You need it to able to have control over your pdfs at any time.",
      register_now: "Register now",
      login: "Login",
      or: "or",
      want_delete: "Do you want to delete this account?",
      want_delete_text: "All the links created will be deleted and it will no longer be possible to recover them.",
      password_confirm: "Enter your password to confirm",
      delete: "Delete",
      cancel: "cancel",
      current_password: "Current password",
      new_password: "New password",
      save: "Save",
      open_browser: "Open in browser",
      add_clipboard: "Add to clipboard",
      generate_qr: "Generate QRCode",
      edit_pdf: "Edit pdf information",
      deactivate: "Deactive link",
      activate: "Active link",
      delete_link: "Delete link",
      navigation_time: "Navigation time",
      total_visits: "Total visits",
      want_delete_link: "Do you want to delete this pdf link?",
      download_qr: "Download QR",
      not_valid_data: "Not valid data",
      email: "Your email",
      password: "Your password",
      not_agreed: "Please agree on Privacy Policy & Terms and Conditions",
      email_exists: "Email address already registred",
      terms_agree: "I agree Privacy Policy & Terms and Conditions",
      edit_link_error: "Please edit the link name or the pdf file",
      link_name: "Link title",
      add_link_error_name: "Please insert a link title",
      add_link_error_pdf: "Please select a pdf",
      create_link: "Create link",
      protect_password: "Protect with password",
      access_password: "Access password",
      add_link_error_password: "Please insert an access password or unselect protection option"
    },
  },
  it: {
    translation: {
      no_link: "Nessun link creato",
      create_new: "Crea link",
      start_now: "Inizia ora",
      enter_password: "Inserisci la tua password per confermare",
      enter_email: "Inserisci il tuo indirizzo email",
      not_valid_password: "Password non valida",
      not_valid_password_2: "La passoword deve contenere almeno 6 caratteri",
      not_valid_password_3: "La passoword attuale non è valida",
      change_password: "Cambia password",
      delete_account: "Elimina account",
      logout: "Logout",
      need_account: "Crea un account per creare ed archiviare i tuoi PDF!",
      need_account_2: "Potrebbe servirti per poter controllare i tuoi PDF in ogni momento da più dispositivi.",
      register_now: "Registrati ora",
      login: "Login",
      or: "oppure",
      want_delete: "Vuoi cancellare questo account?",
      want_delete_text: "Tutti i link creati verranno eliminati e non sarà più possibile recuperarli.",
      password_confirm: "Inserisci la tua password per confermare",
      delete: "Elimina",
      cancel: "annulla",
      current_password: "Password attuale",
      new_password: "Password nuova",
      save: "Salva",
      open_browser: "Apri nel browser",
      add_clipboard: "Copia link",
      generate_qr: "Genera QRCode",
      edit_pdf: "Modifica le informazioni del pdf",
      deactivate: "Disattiva link",
      activate: "Attiva link",
      delete_link: "Elimina link",
      navigation_time: "Tempo di navigazione",
      total_visits: "Visite totali",
      want_delete_link: "Vuoi eliminare questo link?",
      download_qr: "Download QR",
      not_valid_data: "Dati non validi",
      email: "La tua email",
      password: "La tua password",
      not_agreed: "Devi accettare i Termini e le Condizioni & la Privacy Policy",
      email_exists: "Indirizzo email già registrato",
      terms_agree: "Accetto i Termini e le Condizioni & la Privacy Policy",
      edit_link_error: "Modifica il nome del link o il pdf per poter salvare le modifiche",
      link_name: "Titolo del link",
      add_link_error_name: "Inserisci un titolo per il link",
      add_link_error_pdf: "Seleziona un pdf",
      create_link: "Crea link",
      protect_password: "Proteggi con password",
      access_password: "Password di accesso",
      add_link_error_password: "Inserisci una password di accesso o rimuovi l'opzione di protezione"
    },
  },
  es: {
    translation: {
      no_link: "Ningún enlace creado",
       create_new: "Crear enlace",
       start_now: "Empezar ahora",
       enter_password: "Ingrese su contraseña para confirmar",
       enter_email: "Ingrese su dirección de correo electrónico",
       not_valid_password: "Contraseña inválida",
       not_valid_password_2: "La contraseña debe contener al menos 6 caracteres",
       not_valid_password_3: "La contraseña actual no es válida",
       change_password: "Cambiar contraseña",
       delete_account: "Eliminar cuenta",
       logout: "Cerrar sesión",
       need_account: "¡Crea una cuenta para crear y almacenar tus archivos PDF!",
       need_account_2: "Es posible que lo necesite para poder controlar sus archivos PDF en cualquier momento desde varios dispositivos",
       register_now: "Registrarse ahora",
       login: "Iniciar sesión",
       or: "o",
       want_delete: "¿Quieres eliminar esta cuenta?",
       want_delete_text: "Todos los enlaces creados serán eliminados y ya no podrán recuperarse.",
       password_confirm: "Ingrese su contraseña para confirmar",
       delete: "Eliminar",
       cancel: "cancelar",
       current_password: "Contraseña actual",
       new_password: "Nueva contraseña",
       save: "Guardar",
       open_browser: "Abrir en el navegador",
       add_clipboard: "Copiar enlace",
       generar_qr: "Generar código QR",
       edit_pdf: "Editar la información del pdf",
       deactivate: "Desactivar enlace",
       activate: "Activar enlace",
       delete_link: "Eliminar enlace",
       navigation_time: "Tiempo de navegación",
       total_visits: "Visitas totales",
       want_delete_link: "¿Quieres eliminar este enlace?",
       download_qr: "Descarga QR",
       not_valid_data: "Datos inválidos",
       email: "Tu correo electrónico",
       password: "Tu contraseña",
       not_agreed: "Debe aceptar los Términos y condiciones y la Política de privacidad",
       email_exists: "Dirección de correo electrónico ya registrada",
       terms_agree: "Acepto los Términos y condiciones y la Política de privacidad",
       edit_link_error: "Edita el nombre del enlace o el pdf para poder guardar los cambios",
       link_name: "Nombre del enlace",
       add_link_error_name: "Por favor ingrese un nombre de enlace",
       add_link_error_pdf: "Seleccione un pdf",
       create_link: "Crear enlace",
       protect_password: "Proteger con contraseña",
       access_password: "Contraseña de acceso",
       add_link_error_password: "Introduzca una contraseña de acceso o deseleccione la opción de protección"
    },
  },
  ja: {
    translation: {
      no_link: "リンクは作成されていません",
       create_new: "リンクを作成",
       start_now: "今すぐ始める",
       enter_password: "確認のためにパスワードを入力してください",
       enter_email: "メールアドレスを入力してください",
       not_valid_password: "パスワードが無効です",
       not_valid_password_2: "パスワードには少なくとも 6 文字が含まれている必要があります",
       not_valid_password_3: "現在のパスワードは無効です",
       change_password: "パスワードの変更",
       delete_account: "アカウントを削除",
       logout: "ログアウト",
       need_account: "PDF を作成して保存するためのアカウントを作成してください!",
       need_account_2: "複数のデバイスからいつでも PDF を制御できるようにするために必要になる場合があります。",
       register_now: "今すぐ登録",
       login: "ログイン",
       or: "または",
       want_delete: "このアカウントを削除しますか?",
       want_delete_text: "作成されたリンクはすべて削除され、復元できなくなります。",
       password_confirm: "確認のためにパスワードを入力してください",
       delete: "削除",
       cancel: "キャンセル",
       current_password: "現在のパスワード",
       new_password: "新しいパスワード",
       save: "保存",
       open_browser: "ブラウザで開く",
       add_clipboard: "リンクをコピー",
       generate_qr: "QRコードを生成",
       edit_pdf: "pdf情報を編集",
       deactivate: "無効にする",
       activate: "アクティブ",
       delete_link: "リンクを削除",
       navigation_time: "ナビゲーション時間",
       total_visits: "総訪問数",
       want_delete_link: "このリンクを削除しますか?",
       download_qr: "QRをダウンロード",
       not_valid_data: "無効なデータ",
       email: "あなたのメールアドレス",
       password: "あなたのパスワード",
       not_agreed: "利用規約とプライバシー ポリシーに同意する必要があります",
       email_exists: "メールアドレスは既に登録されています",
       terms_agree: "利用規約とプライバシー ポリシーに同意します",
       edit_link_error: "変更を保存できるようにリンク名または PDF を編集してください",
       link_name: "リンクの名前",
       add_link_error_name: "リンク名を入力してください",
       add_link_error_pdf: "pdf を選択",
       create_link: "リンクを作成",
       protect_password: "パスワードで保護",
       access_password: "アクセスパスワード",
       add_link_error_password: "アクセスパスワードを挿入するか、保護オプションの選択を解除してください"
    },
  },
  fr: {
    translation: {
      no_link: "Aucun lien créé",
       create_new : "Créer un lien",
       start_now : "Commencer maintenant",
       enter_password: "Entrez votre mot de passe pour confirmer",
       enter_email : "Entrez votre adresse e-mail",
       not_valid_password : "Mot de passe invalide",
       not_valid_password_2 : "Le mot de passe doit contenir au moins 6 caractères",
       not_valid_password_3 : "Le mot de passe actuel n'est pas valide",
       change_password : "Modifier le mot de passe",
       delete_account : "Supprimer le compte",
       logout: "Déconnexion",
       need_account: "Créez un compte pour créer et stocker vos PDF !",
       need_account_2 : "Vous en aurez peut-être besoin pour pouvoir contrôler vos PDF à tout moment à partir de plusieurs appareils.",
       register_now : "Inscrivez-vous maintenant",
       login: "Login",
       or: "ou",
       want_delete : "Voulez-vous supprimer ce compte ?",
       want_delete_text : "Tous les liens créés seront supprimés et ne pourront plus être récupérés.",
       password_confirm : "Entrez votre mot de passe pour confirmer",
       delete: "Supprimer",
       cancel: "annuler",
       current_password : "Mot de passe actuel",
       new_password : "Nouveau mot de passe",
       save: "Enregistrer",
       open_browser : "Ouvrir dans le navigateur",
       add_clipboard : "Copier le lien",
       generate_qr : "Générer un QRCode",
       edit_pdf : "Modifier les informations du pdf",
       deactivate: "Désactiver le lien",
       activate: "Activer le lien",
       delete_link : "Supprimer le lien",
       navigation_time : "Temps de navigation",
       total_visits : "Nombre total de visites",
       want_delete_link: "Voulez-vous supprimer ce lien ?",
       download_qr : "Télécharger QR",
       not_valid_data : "Données invalides",
       email : "Votre e-mail",
       password : "Votre mot de passe",
       not_agreed : "Vous devez accepter les conditions d'utilisation et la politique de confidentialité",
       email_exists : "Adresse e-mail déjà enregistrée",
       terms_agree : "J'accepte les conditions d'utilisation et la politique de confidentialité",
       edit_link_error : "Modifiez le nom du lien ou le pdf pour pouvoir enregistrer les modifications",
       link_name : "Nom du lien",
       add_link_error_name : "Veuillez entrer un nom de lien",
       add_link_error_pdf : "Sélectionner un pdf",
       create_link : "Créer un lien",
       protect_password : "Protéger avec un mot de passe",
       access_password : "Mot de passe d'accès",
       add_link_error_password : "Veuillez insérer un mot de passe d'accès ou désélectionner l'option de protection"
    },
  },
  de: {
    translation: {
      no_link: "Kein Link erstellt",
       create_new: "Link erstellen",
       start_now: "Jetzt starten",
       enter_password: "Geben Sie Ihr Passwort zur Bestätigung ein",
       enter_email: "Geben Sie Ihre E-Mail-Adresse ein",
       not_valid_password: "Ungültiges Passwort",
       not_valid_password_2: "Das Passwort muss mindestens 6 Zeichen enthalten",
       not_valid_password_3: "Das aktuelle Passwort ist ungültig",
       change_password: "Passwort ändern",
       delete_account: "Konto löschen",
       logout: "Abmelden",
       need_account: "Erstelle ein Konto, um deine PDFs zu erstellen und zu speichern!",
       need_account_2: "Möglicherweise brauchen Sie es, damit Sie Ihre PDFs jederzeit von mehreren Geräten aus steuern können.",
       register_now: "Jetzt registrieren",
       login: "Einloggen",
       or: "oder",
       want_delete: "Möchtest du dieses Konto löschen?",
       want_delete_text: "Alle erstellten Links werden gelöscht und können nicht mehr wiederhergestellt werden.",
       password_confirm: "Geben Sie Ihr Passwort zur Bestätigung ein",
       delete: "Löschen",
       cancel: "stornieren",
       current_password: "Aktuelles Passwort",
       new_password: "Neues Passwort",
       save: "Speichern",
       open_browser: "Im Browser öffnen",
       add_clipboard: "Link kopieren",
       generate_qr: "QRCode generieren",
       edit_pdf: "PDF-Informationen bearbeiten",
       deactivate: "Link deaktivieren",
       activate: "Link aktivieren",
       delete_link: "Link löschen",
       navigation_time: "Navigationszeit",
       total_visits: "Gesamtbesuche",
       want_delete_link: "Möchtest du diesen Link löschen?",
       download_qr: "QR herunterladen",
       not_valid_data: "Ungültige Daten",
       email: "Ihre E-Mail",
       password: "Ihr Passwort",
       not_agreed: "Sie müssen die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie akzeptieren",
       email_exists: "E-Mail-Adresse bereits registriert",
       terms_agree: "Ich stimme den Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie zu",
       edit_link_error: "Bearbeite den Linknamen oder das PDF, um die Änderungen speichern zu können",
       link_name: "Name des Links",
       add_link_error_name: "Bitte geben Sie einen Linknamen ein",
       add_link_error_pdf: "PDF auswählen",
       create_link: "Link erstellen",
       protect_password: "Mit Passwort schützen",
       access_password: "Zugangspasswort",
       add_link_error_password: "Bitte geben Sie ein Zugangspasswort ein oder deaktivieren Sie die Schutzoption"
    },
  }
};

const getUserLanguage = () => window.navigator.userLanguage || window.navigator.language;

i18next
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["en", "it", "es", "ja", "fr", "de"],
    fallbackLng: "en",
    lng: getUserLanguage(),
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;