import { Component } from "react";
import iosBanner from './ios.png';
import androidBanner from './android.png';

class Footer extends Component {
  constructor(props) {
    super(props);
  }


 
  openIOS = () => {
    window.open('https://apps.apple.com/us/app/p-d-f-link-your-pdf-online/id6446084995', '_blank', 'noreferrer');
  }

  openAndroid = () => {
    window.open('https://play.google.com/store/apps/details?id=com.taikasoft.pdflink', '_blank', 'noreferrer');
  }


  render() {
    return (
      <div style={{position: 'fixed', height: '30px', left: '0', bottom: '0', right: '0' }}>
      
      <img onClick={this.openIOS} style={{ cursor: 'pointer', height: '30px' }} src={iosBanner} />
      <img onClick={this.openAndroid} style={{ cursor: 'pointer', marginLeft: '10px', height: '30px' }} src={androidBanner} />

      </div>
    );
  }
}

export default Footer;
