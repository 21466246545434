import { Component } from "react";
import ReactLoading from 'react-loading';
import "./App.css";
import QRCode from "qrcode.react";
import EditPDF from "./EditPDF";
import i18n from 'i18next';
import { MdPassword, MdOpenInBrowser, MdContentCopy, MdTimelapse, MdQrCode, MdOutlineRemoveRedEye, MdModeEditOutline, MdPowerOff, MdOnDeviceTraining, MdDeleteForever } from "react-icons/md";

const downloadQR = () => {
    const canvas = document.getElementById("qr-1");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode-pdf.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

class InfoPDF extends Component {
  constructor(props) {
    super(props);
    console.log(props.info);
    this.state = {
      isLoading: false,
      pdf: props.info,
      showEditPDF: false
    }

  }

  componentDidMount() {
    
   }

   getPDFInfo = () => {
    if(this.state.isLoading){
        return;
    }
    this.setState({ isLoading: true });
    var sessiontoken = localStorage.getItem('client_token');
    
     if(sessiontoken === null){
        window.localStorage.clear(); 
        window.location.reload();
        return;
     }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          id: this.state.pdf.id
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/pdf/info";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
          this.setState({ isLoading: false, pdf: r.info });
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
   }
 
   openInBrowser = () => {
    window.open('https://p-d-f.link/'+this.state.pdf.code, '_blank', 'noreferrer');
   }

   copyLink = () => {
    navigator.clipboard.writeText('https://p-d-f.link/'+this.state.pdf.code);
   }

   generateQRCode = () => {
    this.setState({ showQRCode: true });
   }

   editPDF = () => {
        this.setState({ showEditPDF: true });
   }

   activateLink = () => {
    if(this.state.isLoading){
        return;
    }
    this.setState({ isLoading: true });
    var sessiontoken = localStorage.getItem('client_token');
    
     if(sessiontoken === null){
        window.localStorage.clear(); 
        window.location.reload();
        return;
     }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          id: this.state.pdf.id
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/pdf/action/activate";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
          this.setState({ isLoading: false, pdf: r.info });
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
   }

   confirmDeleteLink = () => {
    if(this.state.isLoading){
        return;
    }
    this.setState({ isLoading: true, showConfirmDelete: false });
    var sessiontoken = localStorage.getItem('client_token');
    
     if(sessiontoken === null){
        window.localStorage.clear(); 
        window.location.reload();
        return;
     }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          id: this.state.pdf.id
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/pdf/action/delete";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
            window.location.reload();
            return;
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
   }

   deleteLink = () => {
        this.setState({ showConfirmDelete: true });
   }
 
   dismiss = () => {
    this.setState({ showConfirmDelete: false, showQRCode: false });
   }

   dismissAndReload = () => {
    this.setState({ showEditPDF: false });
    this.getPDFInfo();
   }

  

  render() {
    return (
      <div style={{ backgroundColor: 'rgb(250,250,250)' }}>
        {this.state.isLoading &&
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.8)',
            width: '100%',
            height: '100%'
              }}>
                <ReactLoading type="spin" color="red" height="30px" width="30px" />
                </div>
      }

                {!this.state.showEditPDF &&
                <div>
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '10px', width: '250px', height: '60px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <img style={{ width: '40px', maxHeight: '50px' }} src={"https://p-d-f.link/cdn/"+this.state.pdf.code+".png"} />
                <div style={{ marginLeft: '10px', width: '65%', textAlign: 'left'}}>
                <div style={{ color: 'rgb(30,30,30)', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{this.state.pdf.name}</div>
                <div style={{ color: 'rgb(100,100,100)', marginTop: '5px', fontSize: '10px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>p-d-f.link/{this.state.pdf.code}</div>
                </div>
                {(this.state.pdf.use_password == '0' && this.state.pdf.active == '1') &&
                <div style={{ padding: '2px', width: '5px', height: '5px', borderRadius: '50%', marginRight: '0px', backgroundColor: 'green' }}></div>
                }
                {(this.state.pdf.use_password == '0' && this.state.pdf.active == '0')  &&
                <div style={{ padding: '2px', width: '5px', height: '5px', borderRadius: '50%', marginRight: '0px', backgroundColor: 'red' }}></div>
                }
                {(this.state.pdf.use_password == '1')  && <MdPassword style={{ marginLeft: '0px' }} color="green" size="15px" />
                }
                </div>
                </div>
                </div>

                <div onClick={this.openInBrowser} style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
             
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("open_browser")}</div>
                <div style={{ width: '15px', height: '12px', marginRight: '10px' }}><MdOpenInBrowser size="12px" color="lightgray" /></div>
               
                </div>
                </div>
                </div>

                <div onClick={this.copyLink} style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
             
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("add_clipboard")}</div>
                <div style={{ width: '15px', height: '12px', marginRight: '10px' }}><MdContentCopy size="12px" color="lightgray" /></div>
               
                </div>
                </div>
                </div>

                <div onClick={this.generateQRCode} style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
             
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("generate_qr")}</div>
                <div style={{ width: '15px', height: '12px', marginRight: '10px' }}><MdQrCode size="12px" color="lightgray" /></div>
               
                </div>
                </div>
                </div>

                <div onClick={this.editPDF} style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
             
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("edit_pdf")}</div>
                <div style={{ width: '15px', height: '12px', marginRight: '10px' }}><MdModeEditOutline size="12px" color="orange" /></div>
               
                </div>
                </div>
                </div>

                <div onClick={this.activateLink} style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >

                {this.state.pdf.active == '1' &&    
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>            
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("deactivate")}</div>
                <div style={{ width: '15px', height: '15px', marginRight: '10px' }}><MdPowerOff size="12px" color="red" /></div>              
                </div>
                }
                {this.state.pdf.active == '0' &&    
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>            
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("activate")}</div>
                <div style={{ width: '15px', height: '15px', marginRight: '10px' }}><MdOnDeviceTraining size="12px" color="green" /></div>              
                </div>
                }
                </div>
                </div>


                <div onClick={this.deleteLink} style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
             
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("delete_link")}</div>
                <div style={{ width: '15px', height: '12px', marginRight: '10px' }}><MdDeleteForever size="12px" color="red" /></div>
               
                </div>
                </div>
                </div>




                <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdTimelapse style={{ marginLeft: '10px' }} color="lightgray" size="15px" />
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("navigation_time")}</div>
                <div style={{ width: '50px', textAlign: 'right', height: '10px', marginRight: '10px', color: 'grey', fontSize: '10px' }}>{this.state.pdf.navigation_time}</div>
               
                </div>
                </div>
                </div>


                <div style={{ marginTop: '5px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdOutlineRemoveRedEye style={{ marginLeft: '10px' }} color="lightgray" size="15px" />
                <div style={{ color: 'rgb(30,30,30)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("total_visits")}</div>
                <div style={{ width: '50px', textAlign: 'right', height: '10px', marginRight: '10px', color: 'grey', fontSize: '10px' }}>{this.state.pdf.total_visits}</div>
               
                </div>
                </div>
                </div>
                </div>
                }

            
                {this.state.showEditPDF &&
                <EditPDF dismiss={this.dismissAndReload} info={this.state.pdf} />}

        <br />

        {this.state.showConfirmDelete && 
                     <div className="popup-box">
                     <div className="box">
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <div style={{ color: 'rgb(30,30,30)', marginTop: '5px', fontSize: '16px', fontWeight: 'bold', width: '100%', textAlign: 'center'}}>{i18n.t("want_delete_link")}</div> 
                     </div>
                        <br /> 
                     <div  style={{ marginTop: '10px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '50%', borderRadius: '5px', backgroundColor: 'red', fontSize: '14px', 
                     textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
                     onClick={this.confirmDeleteLink}
                     >{i18n.t("delete")}</p>
                     </div>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }

                    {this.state.showQRCode && 
                     <div className="popup-box">
                     <div className="box">

                     <QRCode
    id="qr-1"
    value={"https://p-d-f.link/"+this.state.pdf.code}
    size={290}
    level={"H"}
    includeMargin={true}
  />

                    <br /> 
                     
                    <a style={{ cursor: 'pointer', color: 'red', fontWeight: 'bold' }} onClick={downloadQR}> {i18n.t("download_qr")} </a>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'normal', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }

      </div>
    );
  }
}

export default InfoPDF;
