import "./App.css";
import { Component } from "react";
import ReactLoading from 'react-loading';
import Home from "./Home";
import CreatePDF from "./CreatePDF";
import InfoPDF from "./InfoPDF";
import { MdArrowBackIosNew, MdOutlineManageAccounts } from "react-icons/md";
import Account from "./Account";
import Footer from "./Footer";

class App extends Component {
  constructor(props) {
    super(props);
//localStorage.removeItem('client_token');
  
    this.state = {
      isLoading: true,
      showHome: false,
      showCreate: false,
      showPDF: false,
      showAccount: false,
      pdf: null
    }

  }

  componentDidMount() {
   this.prepareRequest();
  }


  prepareRequest = () => {
    var sessiontoken = localStorage.getItem('client_token');
   
    if(sessiontoken === null){
      sessiontoken = this.registerClient();
      return;
    }else{
      this.setState({ isLoading: false, showHome: true });
    } 
   
  }


  registerClient = () =>  {

    var request_options = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: ""
    };

    var apiUrl = "https://p-d-f.link/api/board/v1/hello/client";

    fetch(apiUrl, request_options)
      .then((r) => r.json())
      .then((r) => {
        localStorage.setItem('client_token', r.token);
        this.setState({ isLoading: false, showHome: true });
      })
      .catch((e) => {
        window.localStorage.clear(); 
        window.location.reload();
        return;
      });
    
  }

  showCreatePDF = () => {
    this.setState({ showCreate: true, showHome: false, showPDF: false });
  }

  showHome = () => {
    this.setState({ showHome: true, showCreate: false, showPDF: false, showAccount: false });
  }

  showInfoPDF = (pdf) => {
    this.setState({ showHome: false, showCreate: false, showPDF: true, pdf: pdf });
  }

  showAccount = () => {   
    this.setState({ showHome: false, showCreate: false, showPDF: false, showAccount: true });
  }

  render() {
    return (
      <div className="App">
        {!this.state.isLoading &&
        <div style={{ backgroundColor: 'white' }}>
            {(this.state.showPDF || this.state.showCreate || this.state.showAccount) &&
            <div style={{ cursor: 'pointer' }} onClick={this.showHome}><MdArrowBackIosNew size="20px" style={{ position: 'absolute', left: '10px' }} color="red" /></div>
            }
             {(this.state.showHome) &&
            <div style={{ cursor: 'pointer' }} onClick={this.showAccount}><MdOutlineManageAccounts size="20px" style={{ position: 'absolute', right: '10px' }} color="red" /></div>
            }
            <div style={{ color: 'red', marginTop: '15px', fontWeight: 'bold', fontSize: '14px' }}>P-D-F</div>
            <div style={{ marginTop: '15px',  width: '100%', backgroundColor: 'lightgrey', height: '0.5px'}}></div>
           
        </div>
        }

        {this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '100px'
      }}>
        <ReactLoading type="spin" color="red" height="30px" width="30px" />
        </div>
      }

        {this.state.showHome && <Home showPDF={this.showInfoPDF} createPDF={this.showCreatePDF} />}
        
        {this.state.showCreate && <CreatePDF home={this.showHome} /> }

        {this.state.showPDF && <InfoPDF info={this.state.pdf} home={this.showHome} /> }

        {this.state.showAccount && <Account /> }
        <Footer />
      </div>
    );
  }
}

export default App;
