import { Component } from "react";
import ReactLoading from 'react-loading';
import homeimage from './home_image.png';
import i18n from 'i18next';
import { MdPassword } from "react-icons/md";

class Home extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isLoading: true,
      pdfs: []
    }

  }

  componentDidMount() {
    this.prepareRequest();
   }
 
 
   prepareRequest = () => {
     var sessiontoken = localStorage.getItem('client_token');
    
     if(sessiontoken === null){
        window.localStorage.clear(); 
        window.location.reload();
        return;
     }else{
       this.getHomePage(sessiontoken);
     } 
    
   }


  getHomePage = (sessionToken) => {

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      }),
      body: JSON.stringify({
        token: sessionToken,
      }),
    };

    var apiUrl = "https://p-d-f.link/api/board/v1/pdf/list";

    fetch(apiUrl, requestOptions)
    .then((r) => r.json())
      .then((r) => {
        this.setState({ isLoading: false, pdfs: r.list });
      })
      .catch((e) => {
        localStorage.removeItem('client_token');
        window.location.reload();
        return;
      });
  };

  selectPdf = (pdf) => {
    this.props.showPDF(pdf);
  }

  createPDF = () => {
    this.props.createPDF();
  }

  render() {
    return (
      <div style={{ backgroundColor: 'rgb(250,250,250)' }}>
        {this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '100px'
      }}>
        <ReactLoading type="spin" color="red" height="30px" width="30px" />
        </div>
      }

        {(!this.state.isLoading && this.state.pdfs.length==0) &&
        <div>
            <div style={{ color: 'grey', marginTop: '50px', fontWeight: 'normal', fontSize: '14px' }}>{i18n.t("no_link")}</div>
            <img style={{ marginTop: '50px', width: '250px' }} src={homeimage} />
            <div  style={{ marginTop: '35px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'red', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.createPDF}
            >{i18n.t("start_now")}</p>
            </div>
        </div>
        }

        {(!this.state.isLoading && this.state.pdfs.length>0) &&
        <div>
            <div  style={{ marginTop: '35px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'red', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.createPDF}
            >{i18n.t("create_new")}</p>
            </div>
            <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
            
            <div>             
            { this.state.pdfs.map((element, k) =>
                                    
                <div onClick={() => this.selectPdf(element)} style={{ color: 'white' }} key={k} >
                <div style={{ cursor: 'pointer', marginTop: '10px', width: '250px', height: '60px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <img style={{ width: '40px', maxHeight: '50px' }} src={"https://p-d-f.link/cdn/"+element.code+".png"} />
                <div style={{ marginLeft: '10px', width: '65%', textAlign: 'left'}}>
                <div style={{ color: 'rgb(30,30,30)', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{element.name}</div>
                <div style={{ color: 'rgb(100,100,100)', marginTop: '5px', fontSize: '10px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>p-d-f.link/{element.code}</div>
                </div>
                {(element.use_password == '0' && element.active == '1') &&
                <div style={{ padding: '2px', width: '5px', height: '5px', borderRadius: '50%', marginRight: '0px', backgroundColor: 'green' }}></div>
                }
                {(element.use_password == '0' && element.active == '0')  &&
                <div style={{ padding: '2px', width: '5px', height: '5px', borderRadius: '50%', marginRight: '0px', backgroundColor: 'red' }}></div>
                }
                {(element.use_password == '1')  && <MdPassword style={{ marginLeft: '0px' }} color="green" size="15px" />
                }
                </div>
                </div>
                </div>
                )
            }
        </div>
        </div>
        </div>
        }

        <br />


      </div>
    );
  }
}

export default Home;
