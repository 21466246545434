import { Component } from "react";
import ReactLoading from 'react-loading';
import Register from "./Register";
import Login from "./Login";
import loginimage from './your_idea.png';
import { MdEmail, MdPassword, MdDeleteForever, MdLogout } from "react-icons/md";
import i18n from 'i18next';

class Account extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isLoading: true,
      info: null,
      showConfirmDelete: false,
      password: '',
      newPassword: '',
      showError: '',
      showChangePassword: false
    }

  }
  componentDidMount() {
    this.prepareRequest();
   }
 
 
   prepareRequest = () => {
     var sessiontoken = localStorage.getItem('client_token');
    
     if(sessiontoken === null){
        window.localStorage.clear(); 
        window.location.reload();
        return;
     }else{
       this.getUserInfo(sessiontoken);
     } 
    
   }


  getUserInfo = (sessionToken) => {

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      }),
      body: JSON.stringify({
        token: sessionToken,
      }),
    };

    var apiUrl = "https://p-d-f.link/api/board/v1/user/status-web";

    fetch(apiUrl, requestOptions)
    .then((r) => r.json())
      .then((r) => {
        this.setState({ isLoading: false, info: r.info });
      })
      .catch((e) => {
        window.location.reload();
        return;
      });
  };
 


  registerNow = () => {
    this.setState({ showRegister: true, showLogin: false });
  }

  loginNow = () => {
    this.setState({ showRegister: false, showLogin: true });
  }

  showNoAccountScreen = (reload) => {
    this.setState({ showRegister: false, showLogin: false });
    if(reload){
        this.setState({ isLoading: true });
        this.prepareRequest();
    }
  }

  actionLoading = (start) => {
    this.setState({ isLoading: start });
  }

  deleteAccount = () => {
    this.setState({ showConfirmDelete: true, password: '', showError: '' });
  }

  dismiss = () => {
    this.setState({ showConfirmDelete: false, showChangePassword: false });
  }

  confirmDeleteAccount = () => {
    if(this.state.password.length < 6){
        this.setState({ showError: i18n.t("enter_password") });
        return;
    }
    if(this.state.isLoading){
        return;
    }

    this.setState({ isLoading: true });
    
    var sessiontoken = localStorage.getItem('client_token');
    
    if(sessiontoken === null){
       window.localStorage.clear(); 
       window.location.reload();
       return;
    }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          psw: this.state.password
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/user/action/delete";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
            if(r.status == 'ok'){
                window.localStorage.clear(); 
                window.location.reload();
                return;
            }else{
                this.setState({ showError: i18n.t("not_valid_password"), isLoading: false, password: '' });
            }
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
  }

  doLogout = () => {
    window.localStorage.clear(); 
    window.location.reload();
    return;
   }

   insertPassword = (e) => {
    var insert = String(e.target.value);      
    this.setState({ password: insert, showError: ''});     
}

insertNewPassword = (e) => {
    var insert = String(e.target.value);      
    this.setState({ newPassword: insert, showError: ''});     
}

changePassword = () => {
    this.setState({ showChangePassword: true });
}

confirmChangePassword = () => {
    if(this.state.password.length < 6 || this.state.newPassword.length < 6){
        this.setState({ showError: i18n.t("not_valid_password_2") });
        return;
    }
    if(this.state.isLoading){
        return;
    }

    this.setState({ isLoading: true });
    
    var sessiontoken = localStorage.getItem('client_token');
    
    if(sessiontoken === null){
       window.localStorage.clear(); 
       window.location.reload();
       return;
    }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          current_password: this.state.password,
          new_password: this.state.newPassword
        }),
      };
  
      var apiUrl = "https://p-d-f.link/api/board/v1/user/action/edit-password";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
            if(r.status == 'ok'){
                window.location.reload();
                return;
            }else{
                this.setState({ showError: i18n.t("not_valid_password_3"), isLoading: false, password: '', newPassword: '' });
            }
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
}

  render() {
    return (
      <div>
        {this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    width: '100%',
    height: '100%'
      }}>
        <ReactLoading type="spin" color="red" height="30px" width="30px" />
        </div>
      }

        {this.state.showRegister &&
        <Register loading={this.actionLoading} goBack={this.showNoAccountScreen} />
        }
        {this.state.showLogin &&
        <Login loading={this.actionLoading} goBack={this.showNoAccountScreen} />
        }

            {(!this.state.isLoading && this.state.info != null) &&
                <div>

<div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdEmail style={{ marginLeft: '10px' }} color="lightgray" size="15px" />
                <div style={{ color: 'rgb(100,100,100)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{this.state.info.email}</div>              
                </div>
                </div>
                </div>

                <div onClick={this.changePassword} style={{ marginTop: '10px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdPassword style={{ marginLeft: '10px' }} color="lightgray" size="15px" />
                <div style={{ color: 'rgb(100,100,100)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("change_password")}</div>              
                </div>
                </div>
                </div>

                <div onClick={this.deleteAccount} style={{ marginTop: '10px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdDeleteForever style={{ marginLeft: '10px' }} color="red" size="15px" />
                <div style={{ color: 'rgb(100,100,100)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("delete_account")}</div>              
                </div>
                </div>
                </div>

                <div onClick={this.doLogout} style={{ marginTop: '50px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>  
                <div style={{ marginTop: '0px', cursor: 'pointer', width: '250px', height: '40px', backgroundColor: 'rgb(255,255,255)', borderRadius: '5px', color: 'white', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px', boxShadow: 'none', outline: 'none !important'}} 
                >
                <div style={{ marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <MdLogout style={{ marginLeft: '10px' }} color="lightgrey" size="15px" />
                <div style={{ color: 'rgb(100,100,100)', marginLeft: '10px', fontSize: '12px', fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{i18n.t("logout")}</div>              
                </div>
                </div>
                </div>

                </div>
            }

        {(!this.state.isLoading && this.state.info == null && !this.state.showLogin && !this.state.showRegister) &&
        <div>
            <div style={{ color: 'grey', marginTop: '50px', fontWeight: 'normal', fontSize: '14px' }}>
            {i18n.t("need_account")} 
                <br /><br />
                {i18n.t("need_account_2")} 
                </div>
            <img style={{ marginTop: '20px', width: '250px' }} src={loginimage} />
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div  style={{ marginTop: '15px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '150px', borderRadius: '5px', backgroundColor: 'red', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.registerNow}
            >  {i18n.t("register_now")}</p>
            </div>
            }
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div style={{ color: 'grey', marginTop: '5px', fontWeight: 'normal', fontSize: '14px' }}>  {i18n.t("or")}</div>
            }
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div  style={{ marginTop: '5px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'white', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red'}}
            onClick={this.loginNow}
            >  {i18n.t("login")}</p>
            </div>
            }
        </div>
        }

              

        <br />

        {this.state.showConfirmDelete && 
                     <div className="popup-box">
                     <div className="box">
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <div style={{ color: 'rgb(30,30,30)', marginTop: '5px', fontSize: '16px', fontWeight: 'bold', width: '100%', textAlign: 'center'}}>  {i18n.t("want_delete")}</div> 
                     </div>
                     <br />
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                    <div style={{ color: 'rgb(100,100,100)', marginTop: '15px', fontSize: '14px', fontWeight: 'normal', width: '60%', textAlign: 'center'}}>{i18n.t("want_delete_text")}</div> 
                    </div>

                    <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t("password_confirm")}
                onChange={this.insertPassword}
                />
                </div>
                </div>
                </div>

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'red', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

                        <br /> 
                     <div  style={{ marginTop: '0px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '50%', borderRadius: '5px', backgroundColor: 'red', fontSize: '14px', 
                     textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
                     onClick={this.confirmDeleteAccount}
                     >{i18n.t("delete")}</p>
                     </div>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }

{this.state.showChangePassword && 
                     <div className="popup-box">
                     <div className="box">
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <div style={{ color: 'rgb(30,30,30)', marginTop: '5px', fontSize: '16px', fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Change your password</div> 
                     </div>
                    
                <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="password"  
                name="password"
                value={this.state.password}
                placeholder={i18n.t("current_password")}
                onChange={this.insertPassword}
                />
                </div>
                </div>
                </div>

                <div  style={{marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="password"  
                name="new-password"
                value={this.state.newPassword}
                placeholder={i18n.t("new_password")}
                onChange={this.insertNewPassword}
                />
                </div>
                </div>
                </div>

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'red', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

                        <br /> 
                     <div  style={{ marginTop: '0px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '50%', borderRadius: '5px', backgroundColor: 'red', fontSize: '14px', 
                     textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
                     onClick={this.confirmChangePassword}
                     >{i18n.t("save")}</p>
                     </div>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }

      </div>
    );
  }
}

export default Account;
